import DefaultLayout from "@/components/old-version/components/default/layout/Layout";
import { SingleClientScreen, CriticalErrorScreen } from "@/components/screens";
import {
  getAllClients,
  getClientBySlug,
  getJsonLanguages,
  getJsonPageTemplate,
  getClientDefaultData,
} from "@/lib/api/api";
import { initServiceData } from "@/lib/helpers";
import useAppContext from "@/lib/hooks/useAppContext";
import { Client, ClientService, Language, ModifiedClientConfigs, ClientShort } from "@/lib/types";
import { getCookie } from "cookies-next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import type { GetServerSidePropsContext } from "next/types";
import React, { useEffect } from "react";

type SingleClientPageProps = {
  targetClient: ClientShort | null;
  clientConfig: ModifiedClientConfigs | null;
  defaultClient: Client | null;
  oldLayoutSections: any;
  languages: Language[] | null;
  error: string | null;
};

export default function SingleClientPage({
  targetClient,
  clientConfig,
  defaultClient,
  oldLayoutSections,
  languages,
  error,
}: SingleClientPageProps) {
  const { setCurrentClient, setClientDefault, setLanguages, currentClient, clientDefault, setIsOldVersion } =
    useAppContext();

  useEffect(() => {
    if (languages) {
      setLanguages(languages);
    }
    if (clientConfig && clientConfig.lp_config_v3_data) {
      setCurrentClient(clientConfig.lp_config_v3_data);
    } else {
      setIsOldVersion(true);
    }
    if (defaultClient) {
      setClientDefault(defaultClient);
    }
    if (error) {
      console.log(error);
    }
  }, [clientConfig, languages, defaultClient, error]);

  useEffect(() => {
    if (!currentClient || !Array.isArray(currentClient.services) || !clientDefault) return;
    const { services } = currentClient;
    if (
      services.filter((service) => service.isShow).length == 1 &&
      services.filter((service) => service.isShow)[0].slug == "simplemsk-in-person"
    ) {
      let currentService = services.find((service) => service.isShow) as ClientService;
      let defaultService = defaultClient?.services?.find(
        (service) => service.slide.slug == currentService.slide.slug
      ) as ClientService;
      [currentService, defaultService] = initServiceData(
        currentClient,
        clientDefault,
        currentService,
        defaultService,
        currentService.slide.slug
      );
      const foundIndex = services.findIndex((service) => service.slide.slug == currentService.slide.slug);
      currentClient.services[foundIndex] = currentService;
      clientDefault.services[foundIndex] = defaultService;
    }
  }, [currentClient, clientDefault]);

  const layout = () => {
    switch (true) {
      case Boolean(error):
        return <CriticalErrorScreen />;
      case !currentClient:
        return (
          <DefaultLayout
            clientObj={targetClient}
            clientConfig={clientConfig}
            data={{ sections: oldLayoutSections }}
            assetsUrl=""
          />
        );
      case !!currentClient && !!clientDefault:
        return <SingleClientScreen client={currentClient} clientDefault={clientDefault} />;
      default:
        return null;
    }
  };

  return layout();
}

export const getServerSideProps = async ({ req, res, query }: GetServerSidePropsContext) => {
  const locale = getCookie("NEXT_LOCALE", { req, res }) || "en";
  const slug = query.client_slug as string;
  const translations = await serverSideTranslations(locale || "", ["common"]);
  let targetClient: SingleClientPageProps["targetClient"] = null;
  let clientConfig: SingleClientPageProps["clientConfig"] = null;
  let defaultClient: SingleClientPageProps["defaultClient"] = null;
  let languages: SingleClientPageProps["languages"] = null;
  let error: SingleClientPageProps["error"] = null;
  let oldLayoutSections: SingleClientPageProps["oldLayoutSections"] = null;

  try {
    const clients = await getAllClients();
    targetClient = clients.find((client) => client?.url_friendly_name === slug) || null;
    let isNewLayoutVersion = false;
    if (!targetClient) {
      return {
        redirect: {
          permanent: false,
          destination: "/not-found",
        },
      };
    } else {
      clientConfig = await getClientBySlug(targetClient.id);
      if (clientConfig.lp_config_v3_data) {
        isNewLayoutVersion = true;
      }
      if (clientConfig.lp_config_v3_data && targetClient.url_friendly_name) {
        clientConfig.lp_config_v3_data.slug = targetClient.url_friendly_name;
      }
    }
    languages = await getJsonLanguages();
    if (isNewLayoutVersion) {
      defaultClient = await getClientDefaultData();
    } else {
      oldLayoutSections = await getJsonPageTemplate(clientConfig);
    }
  } catch (e) {
    error = "An error occurred while trying to fetch single client";
    console.log("An error occurred while trying to fetch single client:", e);
  }
  return {
    props: {
      ...translations,
      targetClient,
      clientConfig,
      oldLayoutSections,
      defaultClient,
      languages,
      error,
    },
  };
};
